





















































































import { Component, Vue } from "vue-property-decorator";
import { ISceneCreate } from "@/interfaces";
import { dispatchCreateScene, dispatchGetGroup } from "@/store/main/actions";
import { readGroup } from '@/store/main/getters';

@Component
export default class Create extends Vue {
  public valid = false;
  public loading = true;
  public scene: ISceneCreate = {
    name: '',
    light: 0,
    intensity: 0,
    color: '',
    group_id: undefined,
    x_color: 0,
    y_color: 0,
    color_type: 'cct',
  };

  public reset() {
    this.scene = <ISceneCreate>{};
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  get group(){
    return readGroup(this.$store);
  }

  public async mounted() {
    await dispatchGetGroup(this.$store, Number(this.$router.currentRoute.params.id));
    this.loading = false;
  }

  public async submit() {  
    if (await this.$validator.validateAll()) { 
      this.scene.group_id = Number(this.$router.currentRoute.params.id);
      await dispatchCreateScene(this.$store, this.scene);
      this.$router.back();
    }
  }
}
